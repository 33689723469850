@tailwind base;
@tailwind components;
@tailwind utilities;

*
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input[type="number"] {
  -webkit-appearance: textfield;
     -moz-appearance: textfield;
          appearance: textfield;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none;
}
@layer components {
  /* .my-react-select-container {
  } */
  .my-react-select-container .my-react-select__control {
    @apply bg-white dark:bg-gray-700   ;
  }

  .my-react-select-container .my-react-select__control--is-focused {
    @apply border-none  shadow-none;
  }

  .my-react-select-container .my-react-select__menu {
    @apply bg-white dark:bg-gray-700 ;
  }

  .my-react-select-container .my-react-select__option {
    @apply cursor-pointer text-neutral-600 dark:text-white bg-white hover:bg-gray-200 dark:bg-gray-700 dark:hover:bg-gray-600 ;
  }
  /* .my-react-select-container .my-react-select__option--is-focused {
    @apply bg-neutral-200 dark:bg-neutral-800;
  } */

  /* .my-react-select-container .my-react-select__indicator-separator {
    @apply bg-neutral-400;
  } */

  .my-react-select-container .my-react-select__input-container,
  .my-react-select-container .my-react-select__placeholder,
  .my-react-select-container .my-react-select__single-value {
    @apply text-black dark:text-white;
  }
}