

.multi-select {
    width: 100% !important;
    display: block;
/* padding: 0.625rem !important ; */
background-color: #F9FAFB !important ;
color: #111827 !important ;
font-size: 1.75rem !important ;
line-height: 2rem !important;
width: 100% !important ;
border-radius: 0.5rem !important ;
border-width: 1px !important ;
border-color: #D1D5DB !important ;
outline: none !important;
  }

  .multi-select:focus{
    outline: none !important;
    box-shadow: none !important;
  }

  
  